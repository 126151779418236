let constants = {};
constants.getHostname = function () {
    if (process.env.VUE_APP_ENVIRONMENT === 'test' || process.env.VUE_APP_ENVIRONMENT === 'local') {
        return 't.vizard.video'
    } else {
        return window.location.hostname
    }
}
/**
 * 工作台问号中Tutorials视频地址
 */
constants.VIZARD_TUTORIALS_CREAT_TIME = 1677205510392;
/**
 * 二维码
 */
constants.VIZARD_CODE_GZH = 'https://bluepulse-beijing-docs.oss-cn-beijing.aliyuncs.com/0-vizard/web-static-res/imgs/qrcode/code_vizard_gzh.png?t='+ new Date().getTime()
constants.VIZARD_CODE_CS = 'https://bluepulse-beijing-docs.oss-cn-beijing.aliyuncs.com/0-vizard/web-static-res/imgs/qrcode/code_vizard_customerService.png?t='+ new Date().getTime()
constants.VIZARD_CODE_GROUP = 'https://bluepulse-beijing-docs.oss-cn-beijing.aliyuncs.com/0-vizard/web-static-res/imgs/qrcode/code_vizard_group.png?t='+ new Date().getTime()
/**
 * token名称
 */
constants.SAVE_NAME_PRE = 'v_';
constants.TOKEN = 'auth-token';
constants.LOCAL_TOKEN = constants.SAVE_NAME_PRE + "hash_auth_token";
constants.LOCAL_SAVE_LOGINMOBILE = constants.SAVE_NAME_PRE + "LOCAL_SAVE_LOGINMOBILE";
constants.PHONE_CODE_COUNT_DOWN = constants.SAVE_NAME_PRE + 'PHONE_CODE_COUNT_DOWN';
constants.LOGIN_TYPE = constants.SAVE_NAME_PRE + 'LOGIN_TYPE';
constants.SAVE_WX_LOGIN_REDIRECT_NAME = constants.SAVE_NAME_PRE + 'SAVE_WX_LOGIN_REDIRECT_NAME';
constants.SAVE_WX_BIND_REDIRECT_NAME = constants.SAVE_NAME_PRE + 'NEW_SAVE_WX_BIND_REDIRECT_NAME';
constants.PLATFORM_PC = 1;
constants.PLATFORM_MOBILE = 8;


constants.CURRENT_SELECT_MENU_LIBRARYINDEX = 'CURRENT_SELECT_MENU_LIBRARYINDEX';
constants.CURRENT_SELECT_MENU_TYPE = 'CURRENT_SELECT_MENU_TYPE';
constants.SAVE_FIXE_MENU = 'SAVE_FIXE_MENU';
constants.SHARE_AREA_SIZE = 'SHARE_AREA_SIZE_ARRAY';
constants.CURRENT_RECORD_TAB = 'NEW_CURRENT_RECORD_TAB';
constants.CURRENT_MY_SHARE_TAB = 'NEW_CURRENT_MY_SHARE_TAB';
constants.SAVE_RECORD_MIC_STATE = 'SAVE_RECORD_MIC_STATE';
constants.SET_MAX_RECORD_DURATION = 'SET_MAX_RECORD_DURATION';
constants.VIZARD_OPEN_LOG = 'VIZARD_OPEN_LOG';
constants.SAVE_CLIP_COURSE_SHOW = '_SAVE_CLIP_COURSE_SHOW_WEB';
constants.SAVE_RECORD_FINISH_NUM = 'SAVE_RECORD_FINISH_NUM';
constants.SAVE_ASR_TIPS = '_SAVE_ASR_TIPS';
constants.SAVE_RECORD_TUTORIAL = '_SAVE_RECORD_TUTORIAL';
constants.RECORDING_COMPLETE_FILE_MARKER = '_RECORDING_COMPLETE_FILE_MARKER_'
constants.RECORDING_STATE_AND_TIME = '_RECORDING_STATE_AND_TIME_'
constants.VIZARD_SAVE_DISPLAY_MODEL_NAME = 'VIZARD_SAVE_DISPLAY_MODEL_TYPE_NAME'
constants.WEB_PWA_TIPS_INFO = 'WEB_PWA_TIPS_INFO'
constants.CLOSE_UN_SUPPORT_WINDOW = 'CLOSE_UN_SUPPORT_WINDOW'
constants.CURRENT_OPEN_LIB_ID = 'CURRENT_OPEN_LIB_ID'
constants.VIDEO_LIBRARY_EXPENDED = 'VIDEO_LIBRARY_EXPENDED'
constants.SHARE_CODE = 'SHARE_CODE'
constants.SHARE_TYPE = 'SHARE_TYPE'
constants.EDITOR_SAVE_PAGE_WIDTH = 'EDITOR_SAVE_PAGE_WIDTH_ARRAY'
constants.DOC_TIPS_SHOW = 'DOC_TIPS_SHOW'
constants.TUTORIAL_SHOW = 'TUTORIAL_SHOW'
constants.TEXT_TUTORIAL_SHOW = 'TEXT_TUTORIAL_SHOW'
constants.SHARE_VERIFY_INFO = 'SHARE_VERIFY_INFO'
constants.SHARE_VERIFY_INFO_ARR = 'SHARE_VERIFY_INFO_ARR'
constants.RECORD_CAMERA_INFO = 'RECORD_CAMERA_INFO'
constants.LIBRARY_ARRAY_MENU = 'LIBRARY_ARRAY_MENU';  //视频库列表的数组
constants.HAS_SHOW_EDIT_TUTORIAL = 'HAS_SHOW_EDIT_TUTORIAL'; //视频剪辑功能的新手引导弹窗
constants.EXPORT_GUIDANCE_POP_UP_WINDOW = 'EXPORT_GUIDANCE_POP_UP_WINDOW'; //057导出引导弹窗
constants.EXISTENCE_NEW_EXPORT = 'EXISTENCE_NEW_EXPORT'; //057导出状态红点
constants.SHARE_GUIDANCE_POP_UP_WINDOW = 'SHARE_GUIDANCE_POP_UP_WINDOW'; //057分享功能引导；
constants.EXPORT_VIDEO_FIRSET = 'EXPORT_VIDEO_FIRSET';//057标识第一次导出；
constants.JUMP_TO_PC_SHOW = 'JUMP_TO_PC_SHOW';//是否显示跳转pc查看弹窗
constants.IS_HIDDEN_CLOUD_SPACE_MSG = 'IS_HIDDEN_CLOUD_SPACE_MSG_V2';// 工作台是否隐藏云空间的信息
constants.IS_HIDDEN_CLOUD_SPACE_USE_WINDOW = 'IS_HIDDEN_CLOUD_SPACE_USE_WINDOW';// 工作台是否隐藏云空间使用的窗口
constants.IMAGE_QUALITY_ENHANCEMENT = 'IMAGE_QUALITY_ENHANCEMENT'; //058画质增强弹窗；
constants.IMAGE_QUALITY_ENHANCEMENT_ARRAY = 'IMAGE_QUALITY_ENHANCEMENT_ARRAY'; //不做显示的订单数组
constants.EXPIRED_VIDEO_TIPS_DISPLAY = "EXPIRED_VIDEO_TIPS_DISPLAY";//视频过期提示的弹窗标识

/**
 * 视频地址
 */
constants.VIZARD_DOC_URL = 'https://bluepulse-beijing-docs.oss-cn-beijing.aliyuncs.com/0-web-static/';
constants.getServiceVideoUrl = function () {
    return constants.VIZARD_DOC_URL + constants.getHostname() + '/videos/'
}
constants.VIZARD_INTRO_URL = constants.getServiceVideoUrl() + "vizard-intro-new-with-audio.mp4";
constants.VIZARD_INTRO_WITHOUT_AUDIO_URL = constants.getServiceVideoUrl() + "vizard-intro-new-without-audio.mp4";
constants.VIZARD_PROMOTE_VIDEO_SHARE_URL = constants.getServiceVideoUrl() + "vizard-video-share.mp4";
constants.VIZARD_PROMOTE_VIDEO_CUT_URL = constants.getServiceVideoUrl() + "vizard-video-cut.mp4";
constants.VIZARD_PROMOTE_VIDEO_PPT_URL = constants.getServiceVideoUrl() + "vizard-video-ppt.mp4";
constants.VIZARD_PROMOTE_VIDEO_DISPLAY_URL = constants.getServiceVideoUrl() + "vizard-video-display.mp4";
constants.VIZARD_INDEX_CONTENT_URL = constants.getServiceVideoUrl() + "vizard-index-content.mp4";
constants.VIZARD_INDEX_OPERATE_URL = constants.getServiceVideoUrl() + "vizard-new-index-video.mp4";
constants.VIZARD_INDEX_PROBLEM_URL = constants.getServiceVideoUrl() + "vizard-common-problem.mp4";
constants.VIZARD_CLIP_COURSE_URL = constants.getServiceVideoUrl() + "vizard-convert-text-operation-tutorial.mp4";
constants.VIZARD_WEB_RECORD_TUTORIAL = constants.getServiceVideoUrl() + "vizard-web-record-tutorial.mp4";
/**
 * 首页核心功能三个视频地址
 */
constants.VIZARD_Index_CORE_FUNCTION_VIDEO1 = constants.getServiceVideoUrl() + "vizard-core-function-1-v3.mp4";
constants.VIZARD_Index_CORE_FUNCTION_VIDEO2 = constants.getServiceVideoUrl() + "vizard-core-function-2-v2.mp4";
constants.VIZARD_Index_CORE_FUNCTION_VIDEO3 = constants.getServiceVideoUrl() + "vizard-core-function-3-v5.mp4";
constants.getServiceJsUrl = function () {
    return constants.VIZARD_DOC_URL + constants.getHostname() + '/js'
}
/**
 * 新手引导视频和封面图片地址
 */
constants.VIZARD_TUTORIAL_RECORD_UOLOAD_VIDEO = constants.getServiceVideoUrl() + "record_and_upload.mp4";
constants.VIZARD_TUTORIAL_RECORD_UOLOAD_IMAGE = constants.getServiceVideoUrl() + "record_and_upload.png";
constants.VIZARD_TUTORIAL_SHARE_MANAGE_VIDEO = constants.getServiceVideoUrl() + "share_and_manage.mp4";
constants.VIZARD_TUTORIAL_SHARE_MANAGE_IMAGE = constants.getServiceVideoUrl() + "share_and_manage.png";
constants.VIZARD_TUTORIAL_TRANS_COMMENT_VIDEO = constants.getServiceVideoUrl() + "trans_and_comment.mp4";
constants.VIZARD_TUTORIAL_TRANS_COMMENT_IMAGE = constants.getServiceVideoUrl() + "trans_and_comment.png";
constants.VIZARD_TUTORIAL_EDIT_PROCESS_IMAGE = constants.getServiceVideoUrl() + "edit_and_process.png";
constants.VIZARD_TUTORIAL_EDIT_PROCESS_VIDEO = constants.getServiceVideoUrl() + "edit_and_process.mp4";
/**
 * 视频详情页和pc端分享页视频显示比例相关参数
 */
constants.PAGE_DISPLAY_VIDEOMAXWIDTH = 1400;
constants.PAGE_DISPLAY_MAXMARGIN = 60 * 2;
constants.PAGE_DISPLAY_INITIALHVIDEOWIDTHPERCENTAGE = 0.876;//横屏视频初始化宽度百分比
constants.PAGE_DISPLAY_INITIALVVIDEOHEIGHTPERCENTAGE = 0.896;//竖屏视频初始化高度百分比
constants.PAGE_DISPLAY_DISPLAYSCALEWIDTH = 16;//默认显示比例
constants.PAGE_DISPLAY_DISPLAYSCALEHEIGHT = 9;//默认显示比例

/**
 * 视频分享状态 0-未分享 1-已分享
 */
constants.SHARE_STATE_NO = 0;
constants.SHARE_STATE_YES = 1;
/**
 * FFmpeg加载地址
 */
constants.FFMPEG_LOAD_URL = constants.getServiceJsUrl() + '/ffmpeg/ffmpeg-core.js';
/**
 视频类型。1-客户端录制视频；2-上传视频 3-在线录制 4-绘影 5-转存视频 7-录屏客户端
 */
constants.EDITOR_TYPE_RECORD_CLIENT = 1
constants.EDITOR_TYPE_RECORD_UPLOAD = 2
constants.EDITOR_TYPE_RECORD_WEB = 3
constants.EDITOR_TYPE_HY = 4
constants.EDITOR_TYPE_DUMP_VIDEO = 5
constants.EDITOR_TYPE_SCREEN_CLIENT = 7

/**
 显示模式
 1：平铺模式
 2：列表模式
 */
constants.DISPLAY_MODEL_ITEM = 1
constants.DISPLAY_MODEL_LIST = 2
/**
 录制异常
 1：上传过程中出错
 2：合并完成后出错
 */
constants.RECORD_ERROR_TYPE_UPLOAD = 1
constants.RECORD_ERROR_TYPE_MERGE = 2
/**
 生成文件块时间间隔
 */
constants.GENERATE_FILE_BLOCK_INTERVAL = 10000;
/**
 录制模式
 1：摄像头； 2：摄像头+屏幕； 3：屏幕
 */
constants.RECORD_MODEL_ONLY_CAMERA = 1;
constants.RECORD_MODEL_NEED_CAMERA = 2;
constants.RECORD_MODEL_NO_CAMERA = 3;
//摄像头和边框之间的间距
constants.CAMERA_GAP_WIDTH = 0;
/**
 摄像头形状
 0-无形状铺满（纯人像）1-圆形； 2-方形； 3-矩形
 */
constants.CAMERA_SHAPE_NOT = 0;
constants.CAMERA_SHAPE_CIRCLE = 1;
constants.CAMERA_SHAPE_SQUARE = 2;
constants.CAMERA_SHAPE_RECTANGLE = 3;
/**
 列表每页显示数量
 */
constants.PAGE_SIZE = 24;
/**
 播放器loading出现延迟时间
 */
constants.PLAYER_LOADING_DISPLAY_TIMEOUT = 500;
/**
 前端转码文件大小限制
 */
constants.WEB_FFMPEG_MAX_FILE_SIZE = 800 * 1024 * 1024;

//查看分享详情 1-需要密码 2-不需要密码
constants.SHARE_DETAIL_NEED_CODE = 1;
constants.SHARE_DETAIL_NO_CODE = 2;
/**
 转存状态
 */
constants.DUMPSTATE_SUCCESS = 20;
constants.DUMPSTATE_FAILED = 21;
/**
 * mp4生成状态
 * 1-生成中
 * 2-生成成功
 */
constants.SHARE_VIDEO_GENERATEING = 1;
constants.SHARE_VIDEO_GENERATE_SUCCESS = 2;

//更新日志
constants.RENEW_BLOG_URL = 'https://bluepulse.feishu.cn/mindnotes/bmncnCg5r9sl3LvUMyvNuh4OgEe'
// 阿里云文件片最小size

constants.UPLOAD_FILE_MIN_SIZE = 100 * 1024;

/**
 * 头像颜色
 */

constants.HEADER_BACKGROUND = [
    '#9661ED', '#E45D5D', '#E2A854',
    '#50BE8E', '#6183ED', '#F27649',
    '#33A9D9', '#DDA57A', '#465B4E', '#6D76B2']

/**
 * 视频编辑显示类型
 * 1-普通文本，2-时间占位点，3-删除文本，4-段落，5-段落前空格，6-段位节点， 7-换行节点，8-保留的空行
 */
constants.SENTENCE_TEXT = 1;
constants.SENTENCE_POINT = 2;
constants.SENTENCE_DELETE = 3;
constants.SENTENCE_PARAGRAPH = 4;
constants.SENTENCE_PARAGRAPH_PRE = 5;
constants.SENTENCE_PARAGRAPH_END = 6;
constants.SENTENCE_LINE_FEED = 7;
constants.SENTENCE_LINE_NULL = 8;

constants.USERJSON_VERSION = 4

/**
 * 小程序appID
 */
constants.WECHAT_APPLET_APP_ID = "wxdcb527a8fa69b69e"
constants.WECHAT_APPLET_USERNAME = "gh_8aac0618d33e"
/**
 * 是否是新的第三方用户。0-不是；1-是
 */
constants.THIRDPARTYNEWUSER_NO = 0
constants.THIRDPARTYNEWUSER_YES = 1
//微信登录
constants.APPID_WX = 'wxd0d79f203217fabe';
// constants.APPID = 'wxda5c7f92c0538d86';
constants.VCHAT_URL = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=APPID&redirect_uri=REDIRECT_URI&response_type=code&scope=SCOPE&state=STATE#wechat_redirect'
/**
 * 搜索引擎广告推广
 */
constants.BAIDU_BD_VID = 'VIZARD_BAIDU_BD_VID';
constants.VIZARD_360_QHCLICKID = 'VIZARD_360_QHCLICKID';
constants.WEB_CHANNEL_BAIDU = 'web_baidu_tuiguang';
constants.WEB_CHANNEL_360 = 'web_360_tuiguang';
constants.WEB_CHANNEL_SHARE = 'share';
constants.WEB_CHANNEL_HY = 'huiyingzimu';
constants.WEB_CHANNEL_BLIBILI = 'bilibili';
constants.WEB_CHANNEL = 'web-channel';
constants.WEB_CHANNEL_TOP_BAIDU = 'baidu_top_tuiguang';
constants.zhihu_cb = 'zhihu-cb';
constants.WEB_CHANNEL_ZHIHU = 'zhihu';
constants.VIZARD_WEB_UTM = 'VIZARD_WEB_UTM';

constants.FONT_SIZE_SCALE = 1
/**
 * 分享二维码海报尺寸
 */
constants.SHARE_QR_CODE_POSTER_SIZE_WIDTH = 1050;
constants.SHARE_QR_CODE_POSTER_SIZE_HEIGHT = 1218;

//本地存储 分享码和密码最大数量
constants.SHARE_CODE_SAVE_MAX_NUM = 30;
/**
 * 搜索引擎自然搜索
 */
constants.WEB_BAIDU_ORIGINAL = 'web_baidu_original';
constants.WEB_360_ORIGINAL = 'web_360_original';
constants.WEB_SOGOU_ORIGINAL = 'web_sogou_original';
constants.WEB_BAIDU_ORIGINAL_REFERRER = 'https://www.baidu.com/link';
constants.WEB_360_ORIGINAL_REFERRER = 'https://www.so.com/link';
constants.WEB_SOGOU_ORIGINAL_REFERRER = 'https://www.sogou.com/link';
/**
 * 下载视频状态
 * 20-完成
 * 21-失败
 * 10-生成中；
 * 11-m3u8生成mp4中
 */
constants.DOWNLOAD_VIDEO_SUCCESS = 20;
constants.DOWNLOAD_VIDEO_FAIL = 21;
constants.DOWNLOAD_VIDEO_GENERATING = 10;
constants.DOWNLOAD_VIDEO_CONVERTING = 11;

/**
 * 转存状态
 * 0-关闭
 * 1-开启
 */
constants.DUMP_VIDEO_STATE_CLOSE = 0;
constants.DUMP_VIDEO_STATE_OPEN = 1;
/**
 * 是否设置密码保护
 * 1-设置密码保护
 * 2-为设置密码保护
 */
constants.SET_PWD_PROTECT = 1;
constants.UNSET_PWD_PROTECT = 2;
/**
 * 是否允许被分享者转存视频
 * 1-允许
 * 2-不允许
 */
constants.ALLOW_DUMP_VIDEO = 1;
constants.NOT_ALLOW_DUMP_VIDEO = 2;
/**
 * 是否允许被分享者查看文字转写和注释
 * 1-允许
 * 2-不允许
 */
constants.ALLOW_CHECK_NOTE_CONTENT = 1;
constants.NOT_ALLOW_CHECK_NOTE_CONTENT = 2;
/**
 * 二维码分享渠道
 */
constants.SHARE_QR_CHANNEL_WECHAT = '微信';
constants.SHARE_QR_CHANNEL_FEISHU = '飞书';
constants.SHARE_QR_CHANNEL_DINGDING = '钉钉';
constants.SHARE_QR_CHANNEL_CODE = '二维码';
/**
 * 转存视频类型
 * 1-转存视频；2-切片视频
 */
constants.DUMP_VIDEO_TYPE_DUMP = 1;
constants.DUMP_VIDEO_TYPE_CUT = 2;

/*
* 获取视频库列表数据
* 1-分页； 2-不分页，获取全部
 */
constants.LIBRARY_LIST_TYPE_PAGINATION = 1;
constants.LIBRARY_LIST_TYPE_ALL = 2;
//视频库获取的详情类型
constants.LIBRART_TYPE_VIDEO = 1;
constants.LIBRART_TYPE_FILE = 2;
//视频库层级深度
constants.VIDEO_LIBRARY_DEEP = 7;
//分享给我里面是否显示在顶层
constants.DISPLAY_ON_TOP = 1;
constants.DISPLAY_NOT_TOP = 2;
/**
 * 添加占位时间间隔
 */
constants.INTERVALS_TIME = 200
/**
 * 两个字之间时间间隔
 */
constants.WORD_INTERVALS_TIME = 500
/**
 * 命名的最大长度
 */
constants.MAX_LENGTH_OF_NAME = 64
/**
 * chrome下载地址
 */
constants.CHROME_DOWN_URL = "https://www.google.cn/chrome/";
/**
    音频波形生成状态。0-未生成；1-生成中；2-生成成功；3-生成失败
 */
constants.WAVEFORM_STATE_NOT_GENERATED = 0;
constants.WAVEFORM_STATE_GENERATEDING = 1;
constants.WAVEFORM_STATE_GENERATEDING_SUCCESS = 2;
constants.WAVEFORM_STATE_GENERATEDING_FAIL = 3;
/**
 绘影分享过期状态；1-未过期；2-已过期
 */
constants.HY_SHARE_STATE_NO_EXPIRED = 1;
constants.HY_SHARE_STATE_EXPIRED = 2;

constants.WEB_SOURCE = 'vizard_web_source';
/**
 视频下载类型：3-web录制
 */
constants.DOWNLOAD_TYPE_WEB_RECORD = 3

/**
 查询类型：1-查询录制视频；2-查询转存视频；3-查询上传视频
 */
constants.QUERY_TYPE_RECORD = 1
constants.QUERY_TYPE_DUMP = 2
constants.QUERY_TYPE_UPLOAD = 3

/**
 分享方式 1-url 2-小程序码
 */
constants.SHARE_WAY_URL = 1;
constants.SHARE_WAY_APPLETS = 2;

/**
 需要创建m3u8文件的最大录制ID
 */
constants.CREATE_M3U8FILE_MAX_RECORDID = 817

/**
 * 百度转化
 * 6-点击下载按钮
 * 49-注册激活登录
 * 52-深度使用
 */
constants.BAIDU_NEWTYPE_DOWNLOAD_BUTTON = 6;
constants.BAIDU_NEWTYPE_REGISTER_BUTTON = 49;
constants.BAIDU_NEWTYPE_DEEP_USE = 52;
/**
 * 录制类型
 * 1-客户端
 * 2-在线
 */
constants.RECORDTYPE_CLIENT = 1;
constants.RECORDTYPE_ONLINE = 2;
//剪辑模式
/**
 波形图显示显示最小毫秒数
 */
constants.PANEL_MIN_MSDURATION = 1000 * 5;
/**
 * 句子类型
 * 1-普通句子 2-删除线
 */
constants.SENTENCE_COMMON = 1;
constants.SENTENCE_DELETE_LINE = 2;

/**
 * 页面类型
 * 1-编辑器 2-分享页
 */
constants.LOAD_PAGE_EDITOR = 1;
constants.LOAD_PAGE_PCSHARE = 2;

/**
 * 客户端下载地址
 */
constants.DOWNLOAD_URL_MAC = 'https://bluepulse-beijing-docs.oss-cn-beijing.aliyuncs.com/dmgs/vizard.dmg';
constants.DOWNLOAD_URL_WINDOWS = 'https://bluepulse-beijing-docs.oss-cn-beijing.aliyuncs.com/exes/vizard.exe';

/**
 * OSS 上传分片大小
 */
constants.OSS_CLIENT_FILE_PARTSIZE = Number(512 * 1024);
/**
 * OSS 并发文件块上传个数
 */
constants.OSS_CLIENT_FILE_PARALLEL = 5;
/**
 * 导出状态
 * 20：导出完成
 * 21：导出失败
 */
constants.VIDEO_EXPORT_FINISH = 20;
constants.VIDEO_EXPORT_FAIL = 21;
/**
 * 成品视频的OSS上传状态:
 * 20：上传成功;
 * 21：上传失败;
 */
constants.EXPORT_VIDEO_UPLOAD_FINISH = 20;
constants.EXPORT_VIDEO_UPLOAD_FAIL = 21;
/**
 * 文件直传上传状态
 * 1-上传中；
 * 2-上传成功；
 * 3-上传失败；
 */
constants.DIRECT_UPLOAD_STATE_ING = 1;
constants.DIRECT_UPLOAD_STATE_SUCCESS = 2;
constants.DIRECT_UPLOAD_STATE_FAIL = 3;

/**
 * asrJson状态
 * 13-生成失败
 * 20-上传OSS成功
 * 21-上传OSS失败
 */
constants.ASRJSON_STATE_GENERATE_FAIL = 13;
constants.ASRJSON_STATE_FINISH = 20;
constants.ASRJSON_STATE_UPLOAD_FAIL = 21;

/*
* asrState 失败状态：13、21
* */
constants.ASR_STATE_GENERATE_FAIL = 13;
constants.ASR_STATE_UPLOAD_FAIL = 21;

/**
 * 前端定义的转写状态
 * 1-未转写
 * 2-转写中
 * 3-转写完成
 * 4-转写失败
 */
constants.WEB_ASR_STATE_NO = 1;
constants.WEB_ASR_STATE_PROGRESS = 2;
constants.WEB_ASR_STATE_FINISH = 3;
constants.WEB_ASR_STATE_FAIL = 4;

/**
 * 转写状态
 * 0-未转写
 * 2-已转写
 */
constants.TRANSCRIBESTATE_TRANSCRIBED = 2;
constants.TRANSCRIBESTATE_NOT_TRANSCRIBED = 0;

/**
 * 平台，包名
 */
constants.PLATFORM_MAC = 5;
constants.PLATFORM_WIN = 6;
constants.PACKAGE_NAME = 'ai.vizard.screen.recorder.client';
constants.PACKAGE_NAME_OLD = 'ai.vizard.client';

/**
 * 获客多状态
 * 0-关
 * 1-开
 */
constants.HOKDO_STATE_CLOSE = "0";
constants.HOKDO_STATE_OPEN = "1";

/**
 * 在线录制视频默认宽高
 */
constants.ONLINE_RECORD_VIDEO_WIDTH = 1280;
constants.ONLINE_RECORD_VIDEO_HEIGHT = 720;

/**
 * 摄像头流默认宽高
 */
constants.ONLINE_RECORD_CAMERA_WIDTH = 640;
constants.ONLINE_RECORD_CAMERA_HEIGHT = 480;

/**
 * 绘影语种
 * -1-单语
 */
constants.HY_LANGUAGE_SINGLE = -1;

/**
 *
 * 需要转换繁体的字体ID
 *
 */
constants.CHS2CHT_FONTID = [1370, 1380, 1390, 1400, 1410, 1420];

/**
 * 字幕边距
 */
constants.CAPTION_CUT_CENTER = 2;
constants.CAPTION_CUT_UNCENTER = 1;


constants.OSS_UPLOADFILR_FILEBUSINESSTYPE = 21;
//视频录制
constants.OSS_RECORD_FILEBUSINESSTYPE = 24;
//视频录制最大时长
constants.RECORD_MAX_DURATION = 2 * 60 * 60;
//视频最大暂停时长
constants.RECORD_MAX_PAUSE_DURATION = 4 * 60 * 60 * 1000;
//视频详情
constants.SAVE_VIEW_RECORD_INFO_NAME = 'NEW_VIEW_RECORD_INFO'
//显示分享提示次数
constants.SAVE_SHARE_POPUP_NUM_NAME = 'SAVE_SHARE_POPUP_NUM_NAME'
//是否显示收藏弹窗
constants.SAVE_DISPLAY_COLLECT_NAME = 'SAVE_DISPLAY_COLLECT_NAME'
//是否显示下载视频原因弹窗
constants.SAVE_DISPLAY_DOWNLOAD_REASON_NAME = 'SAVE_DISPLAY_DOWNLOAD_REASON_NAME'
//常见问题回答类型
// 1-文字；2-图片；3-视频; 4-多张图片; 5-单张居中小图
constants.QUESTION_TYPE_TEXT = 1;
constants.QUESTION_TYPE_PIC = 2
constants.QUESTION_TYPE_VIDEO = 3
constants.QUESTION_TYPE_PICS = 4
constants.QUESTION_TYPE_PIC_SMALL = 5
// 文章内容类型：1-文字；2-图片；3-视频; 4-小尺寸图片
constants.ARTICLE_TYPE_TEXT = 1;
constants.ARTICLE_TYPE_PIC = 2;
constants.ARTICLE_TYPE_PIC_SMALL = 4;
constants.ARTICLE_TYPE_VIDEO = 3;
//文章分类：新手引导 录制 剪辑 文本转写 视频管理 分享 其他
constants.CATEGORY_TUTORIAL = 'tutorial';
constants.CATEGORY_RECORD = 'record';
constants.CATEGORY_VIDEO_EDIT = 'videoEdit';
constants.CATEGORY_TRANSLATE = 'translate';
constants.CATEGORY_VIDEO_MANAGE = 'videoManage';
constants.CATEGORY_SHARE = 'share';
constants.CATEGORY_OTHER = 'other';
/**
 *  -1非双语；0-未知语言；999-自动；1-中文；2-英文；3-粤语；4-日语；5-韩语；6-法语；7-泰语；8-俄语；9-葡萄牙语；10-西班牙语；11-德语；12-意大利语；13-越南语
 *  14-阿拉伯语；15-维吾尔语；16-蒙古语(西里尔)；17-藏语；18-繁体中文；19-印度尼西亚语；20-马来语；21-泰米尔语；22-他加禄语；23-柬埔寨语；24-老挝语；25-缅甸语
 *  26-土耳其语；27-中英混合
 */
constants.LANG_DST_NONE = -1;
constants.LANG_UNKONW = 0;
constants.LANG_AUTO = 999;
constants.LANG_ZH = 1;
constants.LANG_EN = 2;
constants.LANG_YUE = 3;
constants.LANG_JP = 4;
constants.LANG_KOR = 5;
constants.LANG_FRA = 6;
constants.LANG_TH = 7;
constants.LANG_RU = 8;
constants.LANG_PT = 9;
constants.LANG_SPA = 10;
constants.LANG_DE = 11;
constants.LANG_IT = 12;
constants.LANG_VIE = 13;
constants.LANG_ARA = 14;
constants.LANG_UY = 15;
constants.LANG_MON = 16;
constants.LANG_TIB = 17;
constants.LANG_CHT = 18;
constants.LANG_ID = 19;
constants.LANG_MAY = 20;
constants.LANG_TAM = 21;
constants.LANG_TGL = 22;
constants.LANG_HKM = 23;
constants.LANG_LAO = 24;
constants.LANG_BUR = 25;
constants.LANG_TR = 26;
constants.LANG_ZH_EN_MIX = 27;

/**
 * 上传文件大小限制
 */
constants.CREATION_UPLOAD_FILE_SIZE = 1024 * 1024 * 1024 * 4;
/**
 * 上传视频最大时长 3小时 1000 * 60 * 60 * 3
 */
constants.CREATION_UPLOAD_FILE_DURATION = 1000 * 60 * 60 * 3;
/**
 * 显示新人引导次数（<= 3）
 */
constants.COUNT_NOTICE_GUIDE = 'COUNT_NOTICE_GUIDE';
/**
 * 系统声音弹窗显示次数
 */
constants.SYSTEM_SOUND_SHOW = 'SYSTEM_SOUND_SHOW';
/**
 * 转码状态
 * 20-成功
 * 21-失败
 * 10-等待转码；
 * 11-转码中
 */
constants.VIDEO_FILE_STATE_SUCCESS = 20;
constants.VIDEO_FILE_STATE_FAIL = 21;
constants.VIDEO_FILE_STATE_WAIT = 10;
constants.VIDEO_FILE_STATE_CONVERTING = 11;
/**
 * 首次获取权限时的弹框
 */
// constants.FIRST_SHOW_MESSAGE = 'FIRST_SHOW_MESSAGE';
/**
 * 侧边栏展开与收起
 */
constants.LEFT_MENU_COLLAPSE = 'LEFT_MENU_COLLAPSE';
/**
 * 我的视频底部操作栏默认选项 --- 单选时
 */
constants.ALL_VIDEO_CHECKED_ARRAY_ONE = [
    {
        width : '84px',
        method : 'share',
        iconName : 'share',
        buttonText : '分享'
    },{
        width : '98px',
        method : 'rename',
        iconName : 'edit-5mf54a3b',
        buttonText : '重命名'
    },{
        width : '98px',
        method : 'addToLibrary',
        iconName : 'add',
        buttonText : '添加至'
    },{
        width : '84px',
        method : 'delete',
        iconName : 'delete',
        buttonText : '删除'
    }
];
/**
 * 我的视频底部操作栏默认选项 --- 多选时
 */
constants.ALL_VIDEO_CHECKED_ARRAY_MORE = [
    {
        width : '98px',
        method : 'addToLibrary',
        iconName : 'add',
        buttonText : '添加至'
    },{
        width : '84px',
        method : 'delete',
        iconName : 'delete',
        buttonText : '删除'
    }
];
/**
 * 我的视频底部操作栏默认选项 --- 存在过期视频时
 */
constants.ALL_VIDEO_CHECKED_ARRAY_EXPIRED = [{
        width : '84px',
        method : 'delete',
        iconName : 'delete',
        buttonText : '删除'
    }
];
/**
 * 视频库底部操作栏默认选项 --- 单选时
 */
constants.PERSONAL_LIB_CHECKED_ARRAY_ONE = [
    {
        width : '84px',
        method : 'share',
        iconName : 'share',
        buttonText : '分享'
    },{
        width : '98px',
        method : 'rename',
        iconName : 'edit-5mf54a3b',
        buttonText : '重命名'
    },{
        width : '98px',
        method : 'add',
        iconName : 'add',
        buttonText : '移动至'
    },{
        width : '84px',
        method : 'delete',
        iconName : 'delete',
        buttonText : '删除'
    }
];
/**
 * 视频库底部操作栏默认选项 --- 多选时
 */
constants.PERSONAL_LIB_CHECKED_ARRAY_MORE = [
    {
        width : '84px',
        method : 'delete',
        iconName : 'delete',
        buttonText : '删除'
    }
];
/**
 * 视频库子级底部操作栏默认选项 --- 单选时
 */
constants.PERSONAL_LIB_SUB_CHECKED_ARRAY_ONE = [
    {
        width : '84px',
        method : 'share',
        iconName : 'share',
        buttonText : '分享'
    },{
        width : '98px',
        method : 'rename',
        iconName : 'edit-5mf54a3b',
        buttonText : '重命名'
    },{
        width : '98px',
        method : 'add',
        iconName : 'add',
        buttonText : '移动至'
    },{
        width : '84px',
        method : 'remove',
        iconName : 'close',
        buttonText : '移除'
    }
];
/**
 * 视频库子级底部操作栏默认选项 --- 多选时
 */
constants.PERSONAL_LIB_SUB_CHECKED_ARRAY_MORE = [
    {
        width : '84px',
        method : 'remove',
        iconName : 'close',
        buttonText : '移除'
    }
];
/**
 * 视频库子级中单个视频底部操作栏默认选项
 */
constants.ALL_VIDEO_SUB_CHECKED_ARRAY_ONE = [
    {
        width : '84px',
        method : 'share',
        iconName : 'share',
        buttonText : '分享'
    },{
        width : '98px',
        method : 'rename',
        iconName : 'edit-5mf54a3b',
        buttonText : '重命名'
    },{
        width : '98px',
        method : 'addToLibrary',
        iconName : 'add',
        buttonText : '添加至'
    },{
        width : '84px',
        method : 'remove',
        iconName : 'close',
        buttonText : '移除'
    }
];
/**
 * 我的导出底部操作栏默认选项 --- 单选时
 */
constants.FINAL_VIDEO_CHECKED_ARRAY_ONE = [
    {
        width : '84px',
        method : 'down',
        iconName : 'download-5mf549of',
        buttonText : '下载'
    },{
        width : '98px',
        method : 'rename',
        iconName : 'edit-5mf54a3b',
        buttonText : '重命名'
    },{
        width : '84px',
        method : 'delete',
        iconName : 'delete',
        buttonText : '删除'
    },
];
/**
 * 我的导出底部操作栏默认选项 --- 多选时
 */
constants.FINAL_VIDEO_CHECKED_ARRAY_MORE = [
   {
        width : '84px',
        method : 'delete',
        iconName : 'delete',
        buttonText : '删除'
    }
];
/**
 * 全选状态
 * 1-全选
 * 2-部分选中
 * 3-未选中
 */
constants.CHECK_ALL_STATUS_ALL = 1;
constants.CHECK_ALL_STATUS_PART = 2;
constants.CHECK_ALL_STATUS_NONE = 3;
/**
 * 模板背景图片基础url参数
 */
constants.BACKGROUND_IMAGE_BASE_ADDRESS = 'https://bluepulse-beijing-docs.oss-cn-beijing.aliyuncs.com/0-vizard/web-static-res/imgs/template/record_template_bg_';
/**
 * 编辑器推荐背景图片基础url参数
 */
constants.RECOMMENDED_BACKGROUND_BASE_URL = 'https://bluepulse-beijing-docs.oss-cn-beijing.aliyuncs.com/0-vizard/background/';
/**
 * 分享类型
 * 1-视频
 * 2-视频库
 */
constants.SHARE_TYPE_VIDEO = 1;
constants.SHARE_TYPE_VIDEO_LIBRARY = 2;

/**
 * 触发状态。
 * 1-待触发；2-触发成功；
 */
constants.TRIGGER_STATE_WAIT = 1;
constants.TRIGGER_STATE_SUCCESS = 2;
/**
 * 视频显示位置
 * 1-显示在分享给我的列表；2-不显示在分享给我的列表（分享给我中的视频库）。
 */
constants.DISPLAY_IN_SHARE_LIST = 1;
constants.DISPLAY_IN_SHARE_VIDEO_LIBRARY = 2;

/**
 * littleData的取值，针对分享详情。
 * 不传此值，认为是0，返回所有数据；1返回第一次detail请求需要的数据(简略信息)。
 */
constants.LITTLEDATA_NO = 0;
constants.LITTLEDATA_YES = 1;

/**
 * 视频编辑器里的操作项
 * 0-剪辑；1-背景；2-字幕
 */
 constants.EDITOR_OPERATION_CLIP = 0;
 constants.EDITOR_OPERATION_BACKGROUND = 1;
 constants.EDITOR_OPERATION_CAPTION = 2;
/**
 * 导出视频背景图类型。0-不添加背景；1-背景纯色填充；2-背景图片
 */
constants.EXPORT_BACKGROUND_TYPE_NOT = 0;
constants.EXPORT_BACKGROUND_TYPE_COLOR = 1;
constants.EXPORT_BACKGROUND_TYPE_IMAGE = 2;

/**
 * 用户上传背景图最大数量
 */
constants.UPLOAD_BACKGROUND_IMAGE_MAX_COUNT = 100;
//字幕颜色选择器预设颜色 数组最大长度
constants.PREDEFINE_COLORS_ARR_MAX_LENGTH = 24;
//编辑器上传数据的版本号
constants.EDITOR_VERSION = 2
//编辑器颜色存储
constants.SAVE_COLOR = 'SAVE_COLOR';
/*
* 原视频圆角类型。0-不带圆角的矩形；1-圆角矩形；2-圆形
* */
constants.VIDEO_CUT_SHAPE_RECTANGLE = 0;
constants.VIDEO_CUT_SHAPE_ROUNDED_RECTANGLE = 1;
constants.VIDEO_CUT_SHAPE_CIRCULAR = 2;
/*
* 视频文本转写结果。1-未转写或转写中；2-转写完成但没有字幕；3-转写失败
* */
constants.VIDEO_TRANS_STATE_SUCCESS = 0;
constants.VIDEO_TRANS_STATE_ING = 1;
constants.VIDEO_TRANS_STATE_NO_TEXT = 2;
constants.VIDEO_TRANS_STATE_FAIL = 3;
/*
* 视频过期状态。1-未过期；2-已过期
* */
constants.VIDEO_EXPIRED_FALSE = 1;
constants.VIDEO_EXPIRED_TRUE = 2;
/**
 * 摄像头的最小尺寸比例
 */
constants.CAMERA_MIN_SIZE_RATIO = 0.07;
/**
 * 画布数据的视频样式基础值
 */
constants.CANVAS_DATA_VIDEO_STYLE_BASE = [0, 0, 1, 1, 0, 0, 1, 1];

/**
 * 播放组件所在页面类型
 * 0-默认，不表示具体页面；1-完成页（视频详情-文本编辑页面）；3-视频详情-视频编辑页面；4-导出内页; 5-pc分享页; 6-h5分享页;
 */
constants.LOAD_PAGE_TYPE_DEFAULT = 0;
constants.LOAD_PAGE_TYPE_DOC = 1;
constants.LOAD_PAGE_TYPE_TRIM = 3;
constants.LOAD_PAGE_TYPE_EXPORT = 4;
constants.LOAD_PAGE_TYPE_SHARE_PC = 5;
constants.LOAD_PAGE_TYPE_SHARE_H5 = 6;
/**
 * 帮助中心文章图片
 * */
constants.VIZARD_DOC_URL = 'https://bluepulse-beijing-docs.oss-cn-beijing.aliyuncs.com/0-web-static/';
constants.getHelpCenterArticleImageUrl = function () {
    return constants.VIZARD_DOC_URL + constants.getHostname() + '/videos/help-center/'
}

/**
 * ai画质增强处理状态。
 * 10-待处理；11-处理中；20-处理成功；21-处理失败；
 */
constants.IMAGE_ENHANCE_WAIT = 10;
constants.IMAGE_ENHANCE_LOADING = 11;
constants.IMAGE_ENHANCE_SUCCESS = 20;
constants.IMAGE_ENHANCE_FAIL = 21;
/**
 * 购买云空间的选项
 * 1-基础云空间；2-大型云空间
 */
constants.CLOUD_SPACE_BASE_OPTION = 1;
constants.CLOUD_SPACE_LARGE_OPTION = 2;

/**
 * 云空间支付选项
 * 1000-基础云空间包月；1001-基础云空间包季；1002-基础云空间包年；1003-大型云空间包月;1006-测试 7 天云空间包
 */
constants.CLOUD_SPACE_PAYMENT_OPTION_MONTH = 1000;
constants.CLOUD_SPACE_PAYMENT_OPTION_SEASON= 1001;
constants.CLOUD_SPACE_PAYMENT_OPTION_YEAR = 1002;
constants.CLOUD_SPACE_PAYMENT_OPTION_LARGE_MONTH = 1003;
constants.CLOUD_SPACE_PAYMENT_OPTION_DAY = 1006;

export default constants
